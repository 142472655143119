import React, { useState } from 'react';
import './index.scss';
import { Layout, LineLayout } from '../../../layout';
import { GanttChart } from '@/components/chart/ganttChart';
// import constructionPlanData from './constructionPlan';
import classnames from 'classnames';
import { Button, Table, Tag } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';

interface DigitalTextProps {
    title?: string,
    digital?: number,
    unit?: string,
    size?: number,
    color?: string
}
const service = axios.create({
    baseURL: 'http://api.mis.zs-tek.com',
    withCredentials: true,
    timeout: 60 * 1000 * 3,
})
// 数字文本
const DigitalText = (props: DigitalTextProps) => {
    return <div className='digitalText' style={{ zoom: props.size }}>
        <div className='digitalText-title'>{props.title}</div>
        <div className='digitalText-digital' style={{ color: props.color }}>{props.digital}</div>
        <div className='digitalText-unit'>{props.unit}</div>
    </div>
}

const handleGanttChart = (data) => {
    let result = { taskContent: [], plannedBeginTime: [], plannedEndTime: [], actualBeginTime: [], actualEndTime: [], };
    data.forEach((element: any) => {
        result.taskContent.push(element.TaskContent);
        result.plannedBeginTime.push(element.ProgramStartDate);
        result.plannedEndTime.push(element.ProgramEndDate);
        result.actualBeginTime.push(element.ActualStartDate);
        result.actualEndTime.push(element.ActualEndDate === null && element.ActualStartDate !== null ? moment().format('YYYY-MM-DD') : element.ActualEndDate);
    });
    return result;
}

const handleTableData = (data: any) => {
    const result = [];
    // console.log(data);
    data.forEach((element: any, index: number) => {
        result.push({
            key: index,
            number: element.Num,
            modular: element.Name,
            content: element.TaskContent,
            priority: element.Priority,
            person: element.Responsible,
            planBegin: element.ProgramStartDate,
            planEnd: element.ProgramEndDate,
            period: element.Duration,
            actualBegin: element.ActualStartDate,
            actualEnd: element.ActualEndDate,
            conduct: element.DaysCompleted,
            surplus: element.IsItDone === '是' ? '--' : element.RemainingDays,
            proportion: _.floor(element.PlannedCompletionRate * 100, 2),
            complete: element.IsItDone,
            state: element.IsItDone === '是' ? '正常' : element.Status,
            ps: element.Remark,
        })
    });
    return result;
}

const handleFilters = (data: any, attribute: string) => {
    let tem = [];
    let result = [];
    data.forEach((element: any) => {
        if (tem.indexOf(element[attribute]) === -1) {
            tem.push(element[attribute]);
            result.push({
                text: element[attribute],
                value: element[attribute]
            });
        }
    });
    return result;
}

const handleDataSummary = (data: Array<any>) => {
    let summary = {
        all: null,
        completed: null,
        inCompleted: null,
    };
    summary.all = data.length;
    data.forEach((element: any) => {
        if (element.IsItDone === '是') {
            summary.completed++;
        } else {
            summary.inCompleted++;
        }
    });
    return summary;
}

const ConstructionPlan = () => {
    const [select, setSelect] = useState(0);
    // 请求数据
    const { data, error, loading } = useRequest(() => {
        return Promise.all([service({ url: '/api/InstitutionalManagement', params: { InternalCode: 'jdd3' } })])
    })
    let returnData = [];
    let summaryData = {
        all: null,
        completed: null,
        inCompleted: null,
    };
    if (loading || error) {
        return <Layout name={`施工计划 (${new Intl.DateTimeFormat("ch", { dateStyle: "short" }).format(Date.now())})`}></Layout >
    }
    if (data !== undefined) {
        returnData = data[0].data;
        summaryData = handleDataSummary(returnData);
        // console.log(returnData);
    }
    // 选项卡
    const DigitalTab = () => {
        return <div className='digitalTab'>
            <div className={classnames({
                'digitalTab-chart': true,
                'digitalTab-select': select === 0,
                'digitalTab-borderRight': select === 1,
            })}
                onClick={() => clickTab(0)}
            >图表</div>
            <div
                className={classnames({
                    'digitalTab-table': true,
                    'digitalTab-select': select === 1,
                    'digitalTab-borderLeft': select === 0,
                })}
                onClick={() => clickTab(1)}
            >详情</div>
        </div >
    }

    const clickTab = (data) => {
        setSelect(data);
    }
    const columns = [
        {
            title: '序号',
            dataIndex: 'number',
            width: 60,
            render: (text, row, index) => <span style={{ fontWeight: 'normal', width: '100%', textAlign: 'center' }}>{text}</span>,
        },
        {
            title: '模块',
            dataIndex: 'modular',
            width: 110,
            filters: handleFilters(returnData, 'Name'),
            onFilter: (value, record) => record.modular.indexOf(value) === 0,
            render: (text, row, index) => <span style={{ fontWeight: 'normal' }}>{text}</span>,
        },
        {
            title: '任务内容',
            dataIndex: 'content',
            width: 180,
            render: (text, row, index) => <span style={{ fontWeight: 'bold' }}>{text}</span>,
        },
        {
            title: '优先级',
            dataIndex: 'priority',
            width: 90,
            filters: handleFilters(returnData, 'Priority'),
            onFilter: (value, record) => record.priority.indexOf(value) === 0,
            render: (priority: any) => {
                let color = priority === '低' ? 'green' : 'volcano';
                if (priority === '中') {
                    color = 'geekblue';
                }
                return (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Tag color={color} key={priority} >
                            {priority}
                        </Tag >
                    </div>
                );
            },
        },
        {
            title: '责任人',
            dataIndex: 'person',
            width: 90,
            filters: handleFilters(returnData, 'Name1'),
            onFilter: (value, record) => record.person.indexOf(value) === 0,
        },
        {
            title: '计划开始',
            dataIndex: 'planBegin',
            width: 110,
            sorter: (a, b) => a.planBegin.replaceAll('-', '') - b.planBegin.replaceAll('-', ''),
            render: (text, row, index) => <span style={{ fontStyle: 'oblique', color: '#0093AB' }}>{text}</span>,
        },
        {
            title: '计划结束',
            dataIndex: 'planEnd',
            width: 110,
            sorter: (a, b) => a.planEnd.replaceAll('-', '') - b.planEnd.replaceAll('-', ''),
            render: (text, row, index) => <span style={{ fontStyle: 'oblique', color: '#0093AB' }}>{text}</span>,
        },
        {
            title: '工期(天)',
            dataIndex: 'period',
            width: 75,
            render: (text, row, index) => <div style={{ fontWeight: 'bold', textAlign: 'center', width: '100%' }}>{text}</div>,
        },
        {
            title: '实际开始',
            dataIndex: 'actualBegin',
            width: 110,
            sorter: (a, b) => a.actualBegin - b.actualBegin,
            render: (text, row, index) => <span style={{ fontStyle: 'oblique', color: '#0093AB' }}>{text}</span>,
        },
        {
            title: '实际结束',
            dataIndex: 'actualEnd',
            width: 110,
            sorter: (a, b) => a.actualEnd - b.actualEnd,
            render: (text, row, index) => <span style={{ fontStyle: 'oblique', color: '#0093AB' }}>{text}</span>,
        },
        {
            title: '已进行天数',
            dataIndex: 'conduct',
            width: 105,
            render: (text, row, index) => <div style={{ fontWeight: 'bold', textAlign: 'center', width: '100%', color: '#389E0D' }}>{text}</div>,
        },
        {
            title: '剩余天数',
            dataIndex: 'surplus',
            width: 105,
            sorter: (a, b) => a.surplus - b.surplus,
            render: (text, row, index) => <div style={{
                fontWeight: 'bold', textAlign: 'center', width: '100%', color: "#FC9918"
            }}>{text}</div>,
        },
        {
            title: '应完成比例',
            dataIndex: 'proportion',
            width: 120,
            sorter: (a, b) => a.proportion - b.proportion,
            render: (text, row, index) =>
                <div style={{
                    fontStyle: 'oblique', fontWeight: 'bold', width: '100%', textAlign: 'center',
                    color: text <= 30 ? '#389E0D' : text <= 60 ? '#1D39C4' : '#D4380D'
                }}>
                    {text}<span style={{ fontSize: '10px' }}>%</span>
                </div >,
        },
        {
            title: '是否完成',
            dataIndex: 'complete',
            width: 105,
            filters: handleFilters(returnData, 'IsItDone'),
            onFilter: (value, record) => record.complete.indexOf(value) === 0,
            render: (complete: any) => {
                let color = complete === '是' ? 'green' : 'volcano';
                return (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Tag color={color} key={complete} >
                            {complete}
                        </Tag >
                    </div>
                );
            },
        },
        {
            title: '状态',
            dataIndex: 'state',
            width: 80,
            filters: handleFilters(returnData, 'Status'),
            onFilter: (value, record) => record.state.indexOf(value) === 0,
            render: (state: any) => {
                let color = state === '正常' ? 'green' : 'volcano';
                if (state === '预警') {
                    color = 'geekblue';
                }
                return (
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <Tag color={color} key={state} >
                            {state}
                        </Tag >
                    </div>
                );
            },
        },
        {
            title: '备注',
            dataIndex: 'ps',
        },
    ];

    return <Layout name={`施工计划 (${new Intl.DateTimeFormat("ch", { dateStyle: "short" }).format(Date.now())})`}>
        <LineLayout margin={[0, 25, 0, 30]} direction="column">
            <LineLayout margin={[10, 0, 0, 0]} gap={50} justify="start">
                <DigitalText title="合计" digital={summaryData.all ?? '--'} size={0.6} color="#05D380" />
                <DigitalText title="已完成" digital={summaryData.completed ?? '--'} size={0.6} color="#05D380" />
                <DigitalText title="未完成" digital={summaryData.inCompleted ?? '--'} size={0.6} color="#EFAD1F" />
                <DigitalText title="完成率" digital={summaryData.all > 0 ? _.floor(summaryData.completed / summaryData.all * 100, 2) : summaryData.all} size={0.6} unit="%" color="#05D380" />
            </LineLayout>
            <DigitalTab />
            {select === 0 ? <GanttChart id="digitalTabGanttChart" data={handleGanttChart(returnData)} height="930px" markLine={moment().format('YYYY-MM-DD')} /> : ''}
            {select === 1 ? <Table columns={columns} dataSource={handleTableData(returnData)} pagination={false} scroll={{ y: 875 }} size="small" bordered
                rowClassName={(record, index) => {
                    let className = 'light-row';
                    if (index % 2 === 1) className = 'dark-row';
                    return className;
                }
                }
            /> : ''}
            <div className='constructionPlanButton'>
                <Button type="primary" icon={<SettingOutlined />} size="middle" href="http://mis.zs-tek.com" target="_blank">
                    管理后台
                </Button>
            </div>
        </LineLayout>
    </Layout >
}

export { ConstructionPlan };