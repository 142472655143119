import React, { useState } from 'react'
import { Layout, LineLayout, CenterLayout, EasyGridLayout } from '@/layout'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { Border1 } from '@/components/border'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { Card } from '@/components/card'
import { LineChart } from '@/components/chart/lineChart'
import { useRequest } from 'ahooks'
import * as API from '@/api/request'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { handleBigCount, handleDataForLineChart, handleDataForMoreLineChart, handleDataForTreemap, searchParams } from '@/utils'
import { formatLineChartXAxis, timeRanges } from '@/utils/time'
import { SingleBarChart } from '@/components/chart/singleBarChart'
import { MultipleLineChart } from '@/components/chart/multipleLineChart'
import _ from 'lodash'
import { Treemap } from '@/components/chart/treemapChart'
import FormatterType from '@/components/chart/formatterType';

const pageName = 'ZS-TEK车间-生产'
/**
 * 处理稼动率图表
 * @param timeRangeType 
 * @param data 
 * @returns 
 */

const Production = () => {
    const [timeRange, setTimeRange] = useState({ ...timeRanges.today(), timeRangeType: '0' })

    //获取页面数据
    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            API.Production.getStatisticValues(null, timeRange),
            API.Production.getCapacityInPairPerHourByTimeRange(null, timeRange),
            API.Production.getCountByTimeRange(null, timeRange),
            API.Production.getallLineCount(timeRange),
            API.Production.getallLineCapacity(timeRange),
            API.Production.getCountByShoeType(null, timeRange)

        ])
    }, { refreshDeps: [timeRange] })

    if (loading) {
        return <Layout name={pageName}>
            <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
            <Loading />
        </Layout>
    }

    if (error) {
        return (<Layout name={pageName}>
            <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
            <Error />
        </Layout>)
    }

    let [statisticValues, capacityc, count, allLineCount, allLineCapacity, shoeTypeCount]: Array<any> = data


    let numCardsLeft = [
        { ...handleBigCount(statisticValues.count, '双'), title: '产量', type: 'count' },
        { ...handleBigCount(statisticValues.capacity, statisticValues.capacityUnit), title: '产能', type: 'count' },
        { count: _.round(statisticValues.taktBySecondsPerPair), suffix: "秒/双", title: '节拍', type: 'count' },
        { count: statisticValues.shoeTypeCount, suffix: "款", title: '款式', type: 'count' }
    ]
    return (
        <Layout name={pageName}>
            <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }}></TimePeriodSelector>
            <LineLayout>
                <LineLayout justify="start">
                    {/* 左边一些数据 */}
                    <LineLayout direction="column" width="380" height="870" margin={[0, 30, 0, 30]} gap={50}>
                        {
                            numCardsLeft.map((item: any) => {
                                return <CenterLayout border={<Border1 />}>
                                    <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.4} type={item.type} />
                                </CenterLayout>
                            })
                        }
                    </LineLayout>
                </LineLayout>
                {/* 中间图表 */}
                <EasyGridLayout width={1100} height={870} gap={30} rows={2} columns={2}>
                    <LineChart id="production1" title="车间产量（双）" height='93%' titleSize={24} data={handleDataForLineChart((timeRange as any).timeRangeType, count)} formatter={timeRange.timeRangeType === '0' ? FormatterType.todayMoment : null} />

                    <LineChart id="production2" title="各产线产量（双）" height='93%' titleSize={24} data={handleDataForMoreLineChart((timeRange as any).timeRangeType, allLineCount)} />
                    <LineChart id="production3" title="车间产能（双/小时）" height='93%' titleSize={24} data={handleDataForLineChart((timeRange as any).timeRangeType, capacityc)} formatter={timeRange.timeRangeType === '0' ? FormatterType.todayMoment : null} />
                    <LineChart id="production4" title="各产线产能（双/小时）" height='93%' titleSize={24} data={handleDataForMoreLineChart((timeRange as any).timeRangeType, allLineCapacity)} />
                </EasyGridLayout>
                <Card title='款式' width={300}>
                    {/* <SingleBarChart id='production3' height={780}></SingleBarChart> */}
                    <Treemap id='shoetype' data={handleDataForTreemap(shoeTypeCount)} />

                </Card>
            </LineLayout>
        </Layout>
    )
}
export { Production }
