/*
 * @Description:基础趋势图组件
 * @Author: huixu
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import option from './barOptions.js'
import './chart.scss'
import { relative } from 'path'

interface AxisStyle {
    fontSize?: number,
    color?: string
}
interface Props {
    id: string
    height?: string | number,
    title?: string
    margin?: string
    data?: number,
    titleSize?: number,
    axisStyle?: AxisStyle,
}
interface State {
    chart: echarts.ECharts
    margin: string
}


let getChartOption = (data: number) => {
    let value = data;
    let color = new echarts.graphic.LinearGradient(
        0, 0, 1, 0, [{
            offset: 0,
            color: "#41D7F3",
        },
        {
            offset: 1,
            color: "#3D9FFF",
        }
    ]
    );
    let option = {
        axisTick: {
            show: false
        },
        series: [{
            name: '',
            type: 'gauge',
            startAngle: 225,
            endAngle: -45,
            min: 0,
            max: 100,
            radius: '2%',
            axisLine: {
                show: true,
                lineStyle: {
                    color: [
                        [1, '#999']
                    ],
                    width: 1,
                    opacity: 1
                }
            },
            title: {
                show: false
            },
            detail: {
                show: false,
                offsetCenter: [0, '70%'],
                //@ts-ignore
                formatter: function (value) {
                    return '';
                },
                rich: {

                    value: {
                        fontSize: 0,
                        fontWeight: 700,
                        color: '#239CFB'
                    },
                    unit: {

                        fontSize: 14,
                        color: '#239CFB',
                        padding: [0, 0, 6, 2]
                    },
                    line: {
                        width: 100,
                        height: 3,
                        shadowColor: 'rgba(229,242,252,0.90)',
                        shadowBlur: 10,
                        backgroundColor: 'rgba(229,242,252,0.90)',
                        borderRadius: 43
                    }
                }
            },
            axisTick: {
                show: false,
                length: 0,
                lineStyle: {
                    color: '#999'
                }
            },
            splitLine: {
                show: false,
                length: 15,
                lineStyle: {
                    color: 'red'
                }
            },
            axisLabel: {
                show: false,
                color: '#999'
            },
            pointer: {
                // show:false,
                width: 6,
                length: 40
            },
            itemStyle: {
                color: color,
                shadowColor: 'rgba(0,138,255,0.45)',
                shadowBlur: 10,
                shadowOffsetX: 2,
                shadowOffsetY: 2
            },
            markPoint: {
                // symbol:'circle',
                // symbolSize:15,
                // itemStyle: {
                //     color: "#fff"
                // },
                data: [{
                    x: "50%",
                    y: "50%",
                    symbol: 'circle',
                    symbolSize: 6,
                    itemStyle: {
                        color: color
                    },
                }, {
                    x: "50%",
                    y: "50%",
                    symbol: 'circle',
                    symbolSize: 15,
                    itemStyle: {
                        color: "#fff"
                    },
                }]
            },
            data: [{
                value: value,
                name: ''
            }]

        },
        {
            name: "",
            type: 'gauge',
            radius: '90%',
            startAngle: 225,
            endAngle: -45,
            min: 0,
            max: 100,
            title: {
                show: false
            },
            detail: {
                show: false
            },
            axisLine: {
                show: true,
                lineStyle: {
                    width: 8,
                    color: [
                        [
                            value / 100, color
                        ],
                        [
                            1, 'rgba(225,225,225,0.4)'
                        ]
                    ],
                }
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: false,
            },
            axisLabel: {
                show: false
            },
            pointer: {
                show: false,
            },
            itemStyle: {
                normal: {
                    color: '#54F200',
                }
            },
            data: [{
                value: value,
                name: ''
            }]
        }
        ]

    }
    return option
}

export class Dashboard extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            chart: null,
            margin: props.margin,
        }
    }



    drawChart() {
        if (this.state.chart) {
            this.setChart()
            return false
        }
        this.setState(
            {
                chart: echarts.init(document.getElementById(`dashbord-chart-${this.props.id}`) as HTMLDivElement),
            },
            () => {
                this.setChart()
            }
        )
    }

    setChart() {

        const option = getChartOption(this.props.data)
        this.state.chart.setOption(option as any, true)
    }

    componentDidMount() {



        this.drawChart()
    }
    componentDidUpdate() {
        this.drawChart()
    }

    render() {
        return (
            <div style={{ margin: this.state.margin, position: 'relative' }}>

                <div id={`dashbord-chart-${this.props.id}`} style={{ width: '100%', height: this.props.height }}>

                </div>


            </div>
        )
    }
}
