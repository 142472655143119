import _ from 'lodash'
import moment from 'moment'
import { searchParams } from './index'

//今天 、本周、最近7天、最近15天(不包含今天)、本月、最近30天，今年

let defaultformat = 'YYYY-MM-DD'
let timeRanges = {
    /**
     * 
     * @returns 今天的开始时间和结束时间
     */
    today: () => {
        return {
            beginTime: moment().format(defaultformat),
            endTime: moment().add(1, 'days').format(defaultformat)
        }
    },
    /**
     * 
     * @returns 周一到今天
     */
    currentWeek: () => {
        let beginTime = moment().day('Monday').format(defaultformat)
        if (moment().day() === 0) {
            beginTime = moment().add(-6, 'day').format(defaultformat)
        }
        return {
            beginTime,
            endTime: moment().add(1, 'day').format(defaultformat)
        }
    },

    /**
     * 
     * @returns 最近七天 不包含当天
     */
    lastWeek: () => {
        return {
            beginTime: moment().add(-7).format(defaultformat),
            endTime: moment().format(defaultformat)
        }
    },
    /**
     * 
     * @returns 最近15天 包含当天
     */
    lastDays15: () => {
        return {
            beginTime: moment().add(-14, 'day').format(defaultformat),
            endTime: moment().add(1, 'day').format(defaultformat)

        }
    },

    ///moment().subtract('days', 6).format('YYYY-MM-DD')
    /**
     * 
     * @returns 最近30天 包含今天
     */
    lastDays30: () => {
        return {
            beginTime: moment().add(-30).format(defaultformat),
            endTime: moment().format(defaultformat)
        }
    },
    /**
     * 
     * @returns 本月开始到今天 包含当天
     */
    currentMonth: () => {
        return {
            beginTime: moment().startOf('month').format(defaultformat),
            endTime: moment().endOf('month').add(1, 'day').format(defaultformat),

        }
    },
    /**
     * 
     * @returns 本年第一天到最后一天
     */
    currentYear: () => {
        return {
            beginTime: moment().startOf('year').format(defaultformat),
            endTime: moment().endOf('year').add(1, 'day').format(defaultformat),
        }
    },
    allYear: () => {
        return {
            beginTime: '2020-01-01',
            endTime: moment().endOf('year').add(1, 'day').format(defaultformat),
        }
    },


}
/**
 * @param timeRangeType 根据时间区间类型格式化x轴的日期
 * @param data 
 * @returns 
 */
const formatLineChartXAxis = (timeRangeType: number, data: Array<any>) => {
    // console.log('timeRangeType', timeRangeType)
    let result: Array<string> = []
    // switch (timeRangeType) {
    //     case 0:
    //         result = data.map((item: any) => moment(item.timeRange.beginTime).format('HH:mm'))
    //         break;
    //     case 1:
    //         result = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期天']
    //         break;
    //     case 2:
    //         result = data.map((item: any) => moment(item.timeRange.beginTime).format('MM/DD'))
    //         break;
    //     case 3:
    //         result = data.map((item: any, idx: number) => idx + 1 + '周')
    //         break;
    //     case 4:
    //         result = data.map((item: any, idx: number) => idx + 1 + '月')
    //         break;
    //     case 5:
    //         result = data.map((item: any, idx: number) => idx + 1 + '季度')
    //         break;
    //     case 6:
    //         result = data.map((item: any) => moment(item.timeRange.beginTime).format('YYYY') + '年')
    //         break;
    //     default:
    //         break;
    // }
    result = data.map((item: any) => item.timeRange.text);
    return result
}
const getTimeDiff = (end: string, start: string) => moment(start).diff(end, 'second')


/**
 * 格式化小时 转换成xx小时xx分钟的形式 超过100个小时则忽略分钟
 * @param hours 
 */
const formatHours = (hours: number) => {
    if (hours <= 1) {
        return _.round(hours * 60) + '分钟'
    }
    if (hours > 100) {
        return _.round(hours) + '小时'
    }
    return Math.floor(hours) + '小时 ' + _.round((hours - Math.floor(hours)) * 60) + '分钟'
}


const timeRangeTypeMap = {
    today: { ...timeRanges.today(), timeRangeType: 0 },
    week: { ...timeRanges.currentWeek(), timeRangeType: 1 },
    month: { ...timeRanges.currentMonth(), timeRangeType: 2 },
    yearByWeek: { ...timeRanges.currentYear(), timeRangeType: 3 },
    yearByMonth: { ...timeRanges.currentYear(), timeRangeType: 4 },
    yearByQuarter: { ...timeRanges.currentYear(), timeRangeType: 5 },
    totalByYear: { ...timeRanges.allYear(), timeRangeType: 6 }
}

const getTimeRangeByUrl = () => {
    const timeRangeType = searchParams().timeRangeType
    if (!timeRangeType) {
        return timeRangeTypeMap.today
    }
    return timeRangeTypeMap[timeRangeType]
}



export { timeRanges, formatLineChartXAxis, getTimeDiff, formatHours, getTimeRangeByUrl }