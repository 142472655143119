import React, { Component } from 'react'
import * as echarts from 'echarts'
import option from './option.js'
import './chart.scss'
import { formatHours } from '@/utils/time'
interface Props {
  id: string
  margin?: string
  title?: string
  data?: Array<any>,
  color?: Array<any>,
  unit?: string,
  type?: string
}
interface State {
  chart: echarts.ECharts
  margin?: string
}
export class PieChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      chart: null,
      margin: props.margin,
    }
  }

  drawChart() {
    if (this.state.chart) {
      this.setChart()
      return false
    }
    this.setState(
      {
        chart: echarts.init(document.getElementById(`pie-chart-${this.props.id}`) as HTMLDivElement),
      },
      () => {
        this.setChart()
      }
    )
  }

  setChart() {
    // [{value:20,name:'11'}]
    option.series[0].data = this.props.data

    if (this.props.type === 'time') {
      option.series[0].label.formatter = (params) => {
        return params.name + '\n' + formatHours(params.value)
      }

    } else {
      option.series[0].label.formatter = (params) => {
        return params.name + '\n' + params.value + this.props.unit
      }

    }

    //@ts-ignore
    if (this.props.color) {
      option.color = this.props.color
    }

    this.state.chart.setOption(option, true)
  }

  componentDidMount() {
    this.drawChart()
  }

  componentDidUpdate() {
    this.drawChart()
  }
  render() {
    return (
      <div className="today-workload" style={{ margin: this.state.margin }}>
        <div className="chart-title">
          <div className='title-point' />
          {this.props.title}
        </div>
        <div id={`pie-chart-${this.props.id}`} style={{ width: '100%', height: '220px' }}></div>
      </div>
    )
  }
}
