import React, { useState } from 'react'
import { LineLayout } from '@/layout'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { timeRanges } from '@/utils/time'
import { cloneDeep } from 'lodash'
import { searchParams } from '@/utils'
import { getTimeRangeByUrl } from '@/utils/time'
import { useMount } from 'ahooks'
import { CustomSelectPanel } from './customSelectPanel'
import './timePeriodSelector.scss'
const toolbarIsVisiable = searchParams().toolbarIsVisiable
interface Props {
    change: Function
}
const timeRangeTypeMap = {
  today: { ...timeRanges.today(), timeRangeType: 0 },
  week: { ...timeRanges.currentWeek(), timeRangeType: 1 },
  month: { ...timeRanges.currentMonth(), timeRangeType: 2 },
  yearByWeek: { ...timeRanges.currentYear(), timeRangeType: 3 },
  yearByMonth: { ...timeRanges.currentYear(), timeRangeType: 4 },
  yearByQuarter: { ...timeRanges.currentYear(), timeRangeType: 5 },
  totalByYear: { ...timeRanges.allYear(), timeRangeType: 6 }
}
const TimePeriodSelector = (props: Props) => {
  let initTabsData: Array<any> = [
    { name: '今日', active: true, timeRangeType: 'today' },
    { name: '本周', active: false, timeRangeType: 'week' },
    { name: '本月', active: false, timeRangeType: 'month' },
    { name: '今年 (按周)', active: false, timeRangeType: 'yearByWeek' },
    { name: '今年(按月)', active: false, timeRangeType: 'yearByMonth' },
    { name: '今年(按季度)', active: false, timeRangeType: 'yearByQuarter' },
    { name: '累积', active: false, timeRangeType: 'totalByYear' },
  ]
  const [tabs, setTabs] = useState(initTabsData)
  const [customButtonActive,setCustomButtonActive] =useState(false)
  /**
   * 获取激活 tab 数据
   * @param naturalTimeRangeType 
   */
  const getActiveTabData = (timeRangeType: string) => {
    let tabs = cloneDeep(initTabsData)
    tabs.forEach((item) => {
      item.active = false
      if (item.timeRangeType === timeRangeType) {
        item.active = true
      }
    })
    return tabs
  }
  useMount(() => {
    //第一次加载进来执行 获取时间的区间类型
    let timeRangeType = searchParams().timeRangeType || 'today'
    setTabs(getActiveTabData(timeRangeType))
    props.change(getTimeRangeByUrl())
  })
  /**
   * 点击时间区间选择 触发自定义事件
   * @param idx 时间区间的索引
   * @param timeRangeType  时间区间的类型
   */
  const clickTimeRangeItme = (idx: number, timeRangeType: string) => {
    setCustomButtonActive(false)
    setTabs(getActiveTabData(timeRangeType))
    props.change(timeRangeTypeMap[timeRangeType], idx)
  }
  /**
   * 自定义查询确认的时候触发
   */
  const confirmCustomSelect = (timeRange:any) => {
    props.change({...timeRange,timeRangeType:2})
    setCustomButtonActive(true)
    setTabs(getActiveTabData(''))
  }
  const jumpToUrl = (timeRangeType: string) => {
    const search = searchParams()
    let searchStr = ''
    search.timeRangeType = timeRangeType
    for (let key in search) {
      searchStr = searchStr + `${key}=${search[key]}&`
    }
    searchStr = searchStr.substr(0, searchStr.length - 1);
    return '?' + searchStr
  }

  return (
    <div className="time-period-selector">
      {
        toolbarIsVisiable === 'false' ? null :
          <LineLayout wrap={true}>
            {tabs.map((item: any, idx: number) => {
              return <Link
                key={idx}
                to={() => jumpToUrl(item.timeRangeType)}
                onClick={() => clickTimeRangeItme(idx, item.timeRangeType)}
                className={classnames({
                  'time-period-selector-item': true,
                  active: item.active,
                })}
              >
                {item.name}
              </Link>
            })}
            <CustomSelectPanel active={customButtonActive} confirm={(timeRange:any)=>confirmCustomSelect(timeRange)} />
          </LineLayout>
      }
    </div>
  )
}
export { TimePeriodSelector }
