import React, { useState } from 'react'

import Icon from '@/components/icon/icon'
import './index.scss'
import Loading from '../loading/loading'
import { useHistory } from 'react-router-dom'
import { useMount, useUpdateEffect } from 'ahooks'

interface CardProps {
  width?: number | string
  height?: number | string
  title?: string
  children?: Object | Array<Object>
  border?: Object
  icon?: string
  jumpTo?: string,
  loading?: boolean,
  error?: boolean,
  showMoreBtn?: boolean
}
/**
 *
 * @param props 普通的内容容器
 * @returns
 */
const Card = (props: CardProps) => {

  let [loading, setLoading] = useState(false)
  useMount(() => setLoading(props.loading))
  let useUpdateFlag = true
  useUpdateEffect(() => {
    useUpdateFlag && setLoading(false)
    useUpdateFlag = false
  }, [props.loading]);



  let history = useHistory()
  let cardStyle = {
    width: props.width ? props.width + 'px' : '100%',
    height: props.height ? props.height + 'px' : '100%',
  }
  const onClickJump = () => {
    if (props.jumpTo === undefined || props.jumpTo === null) {
      return 0;
    }
    else {
      history.push(props.jumpTo);
    }
  }


  return (
    <div className="card" style={cardStyle} onClick={onClickJump}>
      {props.border}
      {props.title ? (
        <div className="title">
          <Icon url={props.icon} size={3} />
          <span>{props.title}</span>
          {
            props.showMoreBtn ? <Icon url={require('../../assets/images/more.png')} size={4} /> : <Icon size={3} />
          }

        </div>
      ) : null}
      {loading ? <Loading /> : props.children}
    </div>


  )
}

export { Card }
