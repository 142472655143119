import React from 'react'
import { Swiper } from '../../components/swiper'
export const LineSwiper = () => {
    return <Swiper interval={1000 * 10} urls={[
        '/line?lineCode=1',
        '/line/production?lineCode=1',
        '/line/quality?lineCode=1',
        '/line/equipment?lineCode=1',
        '/line/environment?lineCode=1'
    ]} />
}