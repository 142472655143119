import React from 'react'
import { useHistory } from 'react-router-dom'
import './index.scss'
import { store } from '@/store'
const Home = () => {
  const history = useHistory()
  const installLineCodes = store.getState().installedLineCodes
  if (installLineCodes.length > 1) {
    history.push('/workShop')
  } else {
    history.push('/line?lineCode=1')
  }
  return null
}
export { Home }
