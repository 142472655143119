import React, { cloneElement } from 'react'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { formatLineChartXAxis } from './time'
import _ from 'lodash'
interface Elements {
    environment?: JSX.Element
    production?: JSX.Element
}
let loading = React.createElement(Loading, null, null)
let error = React.createElement(Error, null, null)

/**
 * 处理简单的折线图数据
 * @param timeRangeType 
 * @param data 
 * @returns 
 */
const handleDataForLineChart = (timeRangeType: number, data: Array<any>) => {
    return {
        yAxis: data.map(item => {
            if (item.count !== null) {
                return _.round(item.count)
            }
            return null
        }),
        xAxis: formatLineChartXAxis(timeRangeType, data)
    }
}

/**
 * 处理多条折线图需要的数据
 * @param timeRangeType 
 * @param data 
 * @returns 
 */
const handleDataForMultipleLineChart = (timeRangeType: number, data: Array<any>) => {
    let result = { xAxis: [], yAxis: [] }
    if (data.length === 0) return result
    result.xAxis = formatLineChartXAxis(timeRangeType, data[0].values)
    result.yAxis = data.map(item => item.values.map((childItem: any) => _.round(childItem.count, 1)))
    return result
}

const handleDataForMoreLineChart = (timeRangeType: number, data: Array<any>) => {
    let result = { xAxis: [], series: [] }
    if (data.length === 0) return result
    result.xAxis = formatLineChartXAxis(timeRangeType, data[0].values)
    result.series = data.map((item, idx) => { return { name: `产线${idx + 1}`, data: item.values.map((childItem: any) => _.round(childItem.count, 1)) } })
    return result
}

const handleDataForOvenLineChart = (timeRangeType: number, data: Array<any>) => {
    const legend = ['烘箱1', '烘箱2', '烘箱3', '烘箱4', '烘箱5', '烘箱6',]
    let result = { xAxis: [], series: [] }
    if (data.length === 0) return result
    result.xAxis = formatLineChartXAxis(timeRangeType, data[0].values)
    result.series = data.map((item, idx) => { return { name: legend[idx], data: item.values.map((childItem: any) => _.round(childItem.count, 1)) } })
    return result
}

const handleDataForTreemap = (data: Array<any>) => {
    return data.map((item: any) => {
        return {
            value: item.count,
            name: item.shoeTypeNameForHuman
        }
    })
}

//处理超过一万的数据 向下保留两位小数
const handleBigCount = (count: number, suffix: string) => {
    if (count > 10000) return { count: _.round(count / 10000, 1), suffix: '万' + suffix }
    return { count: _.round(count), suffix: suffix }
}

/**
 * 获取url里的参数 返回一个对象
 * @returns 
 */
const searchParams = () => {
    let result: any = {}
    let search: string = window.location.href.split('?')[1]
    if (!search) return result
    let params: Array<any> = search.split('&')
    params.forEach(item => {
        result[item.split('=')[0]] = item.split('=')[1]
    })
    return result
}



const handleRequet = (contentELement: JSX.Element): [Function, JSX.Element] => {
    let defaultElement: JSX.Element = loading
    return [(request?: any) => {
        if (!request.loading) {
            if (request.error) { return error }
            let data: any = {}
            data = request.data

            // if (Array.isArray(request.data)) {
            //     // request.data.forEach((item: any) => {
            //     //     data = { ...data, ...item }
            //     // })
            //     data = request.data
            // } else {
            //     data = request.data
            // }


            defaultElement = cloneElement(contentELement, { data })
            // console.log(defaultElement)
            return defaultElement
        }
        return defaultElement
    }, loading]
}

export { searchParams, handleRequet, handleDataForLineChart, handleBigCount, handleDataForMultipleLineChart, handleDataForMoreLineChart, handleDataForOvenLineChart, handleDataForTreemap }