import React, { useState, useEffect } from 'react';
import './index.scss';
import classNames from 'classnames';
import { useRequest, useUnmount } from 'ahooks';
import { EnvironmentApi, Equipment, Logistics, Production } from '@/api/request';
import { searchParams } from '@/utils';
import Loading from '@/components/loading/loading';
import _ from 'lodash';
import { BorderBox9, BorderBox4, Decoration10, BorderBox6, Decoration2, DigitalFlop, BorderBox2, Decoration9, BorderBox7 } from '@jiaminghi/data-view-react';
import Icon from '@/components/icon/icon';
import Header from '@/components/header';
import { store } from '@/store'
import { formatHours } from '@/utils/time';
import { useHistory } from 'react-router'

const lineCode = searchParams().lineCode;
const type = searchParams().type;
let content = <Loading />;
let firstEntry = true;

const handleCardData = (data: any, key: string) => {
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null
    if (!data[0][key] && data[0][key] !== 0) return null
    return Math.floor(data[0][key])
}

const handleArrayToObject = (data: any, target: string) => {
    let result: any = {}
    data.forEach((item: any, index: number) => {
        item.originalPosition = index;
        result[item[target]] = item;
    });
    return result
}

//数组按对象里的属性排序
const handleArraySort = (propertyName: any) => {
    return function (obj1, obj2) {
        var value1 = obj1[propertyName];
        var value2 = obj2[propertyName];
        if (value1 < value2) {
            return -1
        } else if (value1 > value2) {
            return 1
        } else {
            return 0
        }
    }
}

//处理null数据
const handleNull = (data: null) => {
    return data === null ? '--' : data;
}

function IOTD() {
    const history = useHistory();
    let time: NodeJS.Timeout;
    const buttonData = ['烘箱', '胶罐', '机械手', '物流', '能耗', '环境'];
    //机械手状态: '初始化', '伺服关闭', '伺服开启', '戒备状态', '紧急停止', '急停恢复', '系统失效', '未知'
    const [buttonSelected, setButtonSelected] = useState({
        0: true,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
    });
    const [meter, setMeter] = useState(0);
    const [rotation, setRotation] = useState(true);

    const handleButtonClick = (index: number) => {
        let selectedTem = buttonSelected;
        selectedTem[index] = !selectedTem[index];
        setButtonSelected({ ...selectedTem });
        setRotation(false);
        // console.log('selectedTem', selectedTem);
        history.push(`/iot?lineCode=${lineCode}&type=${selectedTem[0] ? 'ovenOrRefrigerator%' : ''}${selectedTem[1] ? 'glueBucket%' : ''}${selectedTem[2] ? 'robot%' : ''}${selectedTem[3] ? 'logistics%' : ''}${selectedTem[4] ? 'enery%' : ''}${selectedTem[5] ? 'environment%' : ''}`);
    }

    const handleRotation = () => {
        // setRotation(!rotation);
        history.push(`/iot?lineCode=${lineCode}&type`);
        setRotation(true);
        setButtonSelected({
            0: true,
            1: false,
            2: false,
            3: false,
            4: false,
            5: false
        });
    }

    const formatter = (number) => {
        const numbers = number.toString().split('').reverse()
        const segs = []

        while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

        return segs.join(',').split('').reverse().join('')
    }

    useEffect(() => {
        // console.log('type', type);
        if (!!type) {
            setRotation(false);
            setButtonSelected({
                0: type.indexOf('ovenOrRefrigerator') > -1,
                1: type.indexOf('glueBucket') > -1,
                2: type.indexOf('robot') > -1,
                3: type.indexOf('logistics') > -1,
                4: type.indexOf('enery') > -1,
                5: type.indexOf('environment') > -1,
            })
        }
    }, [])

    useEffect(() => {
        if (rotation) {
            time = setInterval(() => {
                let selectedTem = {
                    0: false,
                    1: false,
                    2: false,
                    3: false,
                    4: false,
                    5: false
                };
                selectedTem[meter] = true;
                setButtonSelected({ ...selectedTem });
                setMeter(meter => (meter + 1) % 6);
            }, 1000 * 10)
        }
        return () => {
            if (time) {
                clearInterval(time);
            }
        }
    }, [rotation, meter])

    useUnmount(() => {
        firstEntry = true;
    })

    let { data, loading, error } = useRequest(() => {
        return Promise.all([
            EnvironmentApi.getCurrentEnvironmentValues(lineCode),
            Equipment.getCurrentElectricityPowerInW(lineCode),
            Equipment.getTodayElectricityQuantityInKWH(lineCode),
            Equipment.getTotalElectricityQuantityInKWH(lineCode),
            Equipment.getLineCurrentElectricities(lineCode),
            Equipment.getGlueBucketCurrentParameters(lineCode),
            Equipment.getOvensCurrentParameters(lineCode),
            Equipment.getRobotCurrentStatuses(lineCode),
            Logistics.getWorkstationCurrentPalletCounts(lineCode),
            Logistics.getStatisticValuesByWorkStation(lineCode),
            Production.getTotalCount(lineCode),
            Production.getTodayCount(lineCode),

        ])
    }, { pollingInterval: 1000 * 10 })

    let ovenData, rubberTankData, robotData, lineEnergyData, environmentData, power, logistics, logisticsArr, logisticsAnalysis, productionData;

    ovenData = [
        {
            code: '1',
            top: 605,
            left: 640,
            content: [null, null],
        },
        {
            code: '2',
            top: 605,
            left: 915,
            content: [null, null],
        },
        {
            code: '3',
            top: 605,
            left: 1035,
            content: [null, null],
        },
        {
            code: '4',
            top: 520,
            left: 1185,
            content: [null, null],
        },
        {
            code: '5',
            top: 110,
            left: 1180,
            content: [null, null],
        },
        {
            code: '6',
            top: 110,
            left: 730,
            content: [null, null],
        },
        {
            code: '7',
            top: 110,
            left: 600,
            content: [null, null],
        },
        {
            code: '8',
            top: 270,
            left: 25,
            content: [null, null],
        },
        {
            code: '9',
            top: 10,
            left: 730,
            content: [null, null],
        },
        {
            code: '10',
            top: 10,
            left: 600,
            content: [null, null],
        },
    ];
    rubberTankData = [
        {
            code: "1",
            top: 335,
            left: 1440,
            content: [null, null],
        },
        {
            code: "2",
            top: 335,
            left: 1285,
            content: [null, null],
        },
        {
            code: "3",
            top: 335,
            left: 975,
            content: [null, null],
        },
        {
            code: "4",
            top: 335,
            left: 830,
            content: [null, null],
        },
        {
            code: "5",
            top: 25,
            left: 1265,
            content: [null, null],
        },
        {
            code: "6",
            top: 25,
            left: 995,
            content: [null, null],
        },
    ];
    robotData = [
        {
            code: "1",
            top: 415,
            left: 1680,
            content: null,
        },
        {
            code: "2",
            top: 350,
            left: 1660,
            content: null,
        },
        {
            code: "3",
            top: 285,
            left: 1640,
            content: null,
        },
        {
            code: "4",
            top: 250,
            left: 1505,
            content: null,
        },
        {
            code: "5",
            top: 250,
            left: 1390,
            content: null,
        },
        {
            code: "6",
            top: 250,
            left: 1070,
            content: null,
        },
        {
            code: "7",
            top: 250,
            left: 945,
            content: null,
        },
        {
            code: "8",
            top: 125,
            left: 1300,
            content: null,
        },
        {
            code: "9",
            top: 125,
            left: 1030,
            content: null,
        },
    ];
    lineEnergyData = [
        {
            title: '当前功率',
            content: null,
            unit: 'kW',
        },
        {
            title: '今日能耗',
            content: null,
            unit: '度',
        },
        {
            title: '累积能耗',
            content: null,
            unit: '万度',
        }
    ];
    environmentData = [
        {
            title: '温度',
            content: null,
            unit: '℃',
        },
        {
            title: '湿度',
            content: null,
            unit: '%',
        },
        {
            title: '噪音',
            content: null,
            unit: 'dB',
        },
        {
            title: 'PM2.5',
            content: null,
            unit: 'ug/m³',
        }
    ];
    power = [
        {
            title: 'A相',
            content: [null, null],
        },
        {
            title: 'B相',
            content: [null, null],
        },
        {
            title: 'C相',
            content: [null, null],
        },
    ];
    logistics = {
        'D1': [
            {
                code: "1",
                top: 460,
                left: 1730,
                isFull: null,
            },
            {
                code: "2",
                top: 360,
                left: 1705,
                isFull: null,
            },
            {
                code: "3",
                top: 295,
                left: 1680,
                isFull: null,
            },
            {
                code: "4",
                top: 240,
                left: 1555,
                isFull: null,
            },
            {
                code: "5",
                top: 240,
                left: 1445,
                isFull: null,
            },
            {
                code: "6",
                top: 205,
                left: 1190,
                isFull: null,
            },
            {
                code: "7",
                top: 240,
                left: 1005,
                isFull: null,
            },
            {
                code: "8",
                top: 240,
                left: 885,
                isFull: null,
            },
            {
                code: "9",
                top: 205,
                left: 680,
                isFull: null,
            },
            {
                code: "10",
                top: 220,
                left: 465,
                isFull: null,
            },
            {
                code: "11",
                top: 240,
                left: 185,
                isFull: null,
            },
            {
                code: "12",
                top: 340,
                left: 120,
                isFull: null,
            },
            {
                code: "13",
                top: 495,
                left: 90,
                isFull: null,
            },
            {
                code: "16",
                top: 560,
                left: 255,
                isFull: null,
            },
            {
                code: "18",
                top: 560,
                left: 355,
                isFull: null,
            },
            {
                code: "14",
                top: 560,
                left: 560,
                isFull: null,
            },
            {
                code: "15",
                top: 560,
                left: 665,
                isFull: null,
            },
            {
                code: "19",
                top: 560,
                left: 800,
                isFull: null,
            },
            {
                code: "17",
                top: 560,
                left: 980,
                isFull: null,
            },
        ],
        'D2': [
            {
                code: "1",
                top: 460,
                left: 1730,
                isFull: null,
            },
            {
                code: "2",
                top: 360,
                left: 1705,
                isFull: null,
            },
            {
                code: "3",
                top: 295,
                left: 1680,
                isFull: null,
            },
            {
                code: "4",
                top: 240,
                left: 1555,
                isFull: null,
            },
            {
                code: "5",
                top: 240,
                left: 1445,
                isFull: null,
            },
            {
                code: "6",
                top: 205,
                left: 1190,
                isFull: null,
            },
            {
                code: "7",
                top: 240,
                left: 1005,
                isFull: null,
            },
            {
                code: "8",
                top: 240,
                left: 885,
                isFull: null,
            },
            {
                code: "9",
                top: 205,
                left: 680,
                isFull: null,
            },
            {
                code: "10",
                top: 340,
                left: 120,
                isFull: null,
            },
            {
                code: "11",
                top: 495,
                left: 90,
                isFull: null,
            },
        ]
    }
    logisticsArr = [];
    logisticsAnalysis = {};
    productionData = [
        {
            title: '累计产量',
            content: null,
            unit: '万双'
        },
        {
            title: '今日产量',
            content: null,
            unit: '双'
        }
    ];

    if (!error && (!loading || !firstEntry)) {
        // if (false) {
        firstEntry = false;
        let [currentEnvironmentValues, currentElectricityPowerInW, todayElectricityQuantityInKWH, totalElectricityQuantityInKWH, lineCurrentElectricities, glueBucketCurrentParameters, ovensCurrentParameters, robotCurrentStatuses, workstationCurrentPalletCounts, statisticValuesByWorkStation, totalCount, todayCount]: any = data;
        ovenData.forEach((item: any) => {
            let temporary = handleArrayToObject(ovensCurrentParameters, 'ovenCode')[item.code];
            if (temporary) {
                item.content[0] = (!temporary.temperatureInC ? temporary.temperatureInC : Math.floor(temporary.temperatureInC * 10) / 10);
                item.content[1] = (!temporary.powerInW ? Number(temporary.powerInW) : Math.floor(temporary.powerInW / 1000 * 10) / 10);
            }
        });
        glueBucketCurrentParameters.forEach((item: any, index: number) => {
            rubberTankData[index].content[0] = (!item.positionInMillimeter ? item.positionInMillimeter : Math.floor(item.positionInMillimeter));
            rubberTankData[index].content[1] = (!item.positionInMillimeter ? item.positionInMillimeter : Math.floor(item.pressureInPa / 1000000 * 100) / 100);
        });
        rubberTankData.forEach((item: any) => {
            let temporary = handleArrayToObject(glueBucketCurrentParameters, 'bucketCode')[item.code];
            if (temporary) {
                item.content[0] = (!temporary.positionInMillimeter ? temporary.positionInMillimeter : Math.floor(temporary.positionInMillimeter));
                item.content[1] = (!temporary.positionInMillimeter ? temporary.positionInMillimeter : Math.floor(temporary.pressureInPa / 1000000 * 100) / 100);
            }
        });
        robotData.forEach((item: any) => {
            let temporary = handleArrayToObject(robotCurrentStatuses, 'workStationCode')[item.code];
            if (temporary) {
                item.content = !temporary.controllerState ? temporary.controllerState : temporary.controllerState;
            }
        });
        lineEnergyData = [
            {
                title: '当前功率',
                content: !currentElectricityPowerInW ? currentElectricityPowerInW : Math.floor(currentElectricityPowerInW / 1000 * 100) / 100,
                unit: 'kW',
            },
            {
                title: '今日能耗',
                content: !todayElectricityQuantityInKWH ? todayElectricityQuantityInKWH : Math.floor(todayElectricityQuantityInKWH),
                unit: '度',
            },
            {
                title: '累积能耗',
                content: !totalElectricityQuantityInKWH ? totalElectricityQuantityInKWH :
                    Math.floor(totalElectricityQuantityInKWH) > 9999 ? _.round(totalElectricityQuantityInKWH / 10000, 1) : Math.floor(totalElectricityQuantityInKWH),
                unit: !totalElectricityQuantityInKWH ? '度' :
                    Math.floor(totalElectricityQuantityInKWH) > 9999 ? '万度' : '度',
            }
        ];
        environmentData = [
            {
                title: '温度',
                content: !currentEnvironmentValues.temperatureInC ? currentEnvironmentValues.temperatureInC : Math.floor(currentEnvironmentValues.temperatureInC * 10) / 10,
                unit: '℃',
            },
            {
                title: '湿度',
                content: !currentEnvironmentValues.humidness ? currentEnvironmentValues.humidness : Math.floor(currentEnvironmentValues.humidness * 100),
                unit: '%',
            },
            {
                title: '噪音',
                content: !currentEnvironmentValues.noiseIndB ? currentEnvironmentValues.noiseIndB : Math.floor(currentEnvironmentValues.noiseIndB * 10) / 10,
                unit: 'dB',
            },
            {
                title: 'PM2.5',
                content: !currentEnvironmentValues.pM25 ? currentEnvironmentValues.pM25 : Math.floor(currentEnvironmentValues.pM25 * 10) / 10,
                unit: 'ug/m³',
            }
        ];
        power = [
            {
                title: 'A相',
                content: [handleCardData(lineCurrentElectricities, 'voltageAInV'), handleCardData(lineCurrentElectricities, 'currentAInA')],
            },
            {
                title: 'B相',
                content: [handleCardData(lineCurrentElectricities, 'voltageBInV'), handleCardData(lineCurrentElectricities, 'currentBInA')],
            },
            {
                title: 'C相',
                content: [handleCardData(lineCurrentElectricities, 'voltageCInV'), handleCardData(lineCurrentElectricities, 'currentCInA')],
            },
        ];
        logisticsArr = statisticValuesByWorkStation.sort(handleArraySort('isFullHours')).reverse().slice(0, 3);
        if (logisticsArr[0].usedHours === 0) {
            logisticsArr = []
        } else {
            logisticsAnalysis = handleArrayToObject(logisticsArr, 'workStationCode');
        }
        logistics[store.getState().projectInfo.shortCode].forEach((item: any) => {
            let temporary = handleArrayToObject(workstationCurrentPalletCounts, 'workStationCode')[item.code];
            if (temporary) {
                item.isFull = !temporary.isFull ? false : true;
            }
        });
        productionData[0].content = _.floor(totalCount / 10000, 1);
        productionData[1].content = _.floor(todayCount, 0);
    }

    content =
        <div className='upgradesecond-iotD-container'>
            {/* 头部 */}
            {/* <Header name={headerTitle} /> */}
            <div className='upgradesecond-iot-header'>
                <Header name={store.getState().projectInfo.name} />
            </div>
            {/* 内容 */}
            <div className='upgradesecond-iot-body'>
                {/* 左侧布局图 */}
                <div className='upgradesecond-iot-body-left'>
                    <BorderBox9 color={['#3F96A5', '#3F96A5']}>
                        {/* 按钮列表 */}
                        <div className='upgradesecond-iot-list'>
                            {buttonData.map((item: any, index: number) => {
                                return <div
                                    className={classNames({
                                        'button': true,
                                        'selected': buttonSelected[index],
                                    })}
                                    onClick={() => handleButtonClick(index)}
                                    key={index}
                                >{item}</div>
                            })}
                        </div>
                        <Decoration9 dur={rotation ? 3 : 0} color={['#3f96a547', '#3F96A5']} style={{ width: '70px', height: '70px', position: 'absolute', top: `20px`, right: `30px`, color: '#fff', cursor: 'pointer' }}><div
                            style={{ width: '70px', height: '70px', zIndex: 999, lineHeight: '70px', textAlign: 'center' }}
                            onClick={() => handleRotation()}
                        >{rotation ? '轮播中' : '轮播'}</div>
                        </Decoration9>
                        <Decoration10 color={['#3F96A5']} style={{ width: '100%', height: '5px', padding: '8px 25px 0' }} />
                        {/* 悬浮信息框 */}
                        <div className='upgradesecond-iot-illustration'>
                            <img src={require('../../../../assets/images/DLine1-4.png')} alt="" className='upgradesecond-iot-illustration-bg' />
                            {ovenData.map((item: any, index: number) => {
                                return (
                                    !handleArrayToObject(store.getState().ovenOrRefrigerators, 'code')[item.code] ?
                                        '' :
                                        <div key={index} style={{ width: '70px', height: '85px', position: 'absolute', backgroundColor: '#141414c4', top: `${item.top}px`, left: `${item.left}px`, transition: `opacity ${0.2 + 0.15 * index}s` }}
                                            className={classNames({
                                                'hideAnimation': true,
                                                'hideGraphical': !buttonSelected[0],
                                            })}
                                        >
                                            <BorderBox7 color={['#000', '#D9D9D9']}>
                                                <div className='oven'>
                                                    <div className='illustration-title'>{handleArrayToObject(store.getState().ovenOrRefrigerators, 'code')[item.code].name.replace(new RegExp("烘箱"), "")}</div>
                                                    <div className='illustration-data'><span className='number'>{handleNull(item.content[0])}</span>℃</div>
                                                    <div className='illustration-data'><span className='number'>{handleNull(item.content[1])}</span>kW</div>
                                                </div>
                                            </BorderBox7>
                                        </div>
                                )
                            })}
                            {robotData.map((item: any, index: number) => {
                                return (
                                    <div key={index} style={{ width: '30px', height: '30px', borderRadius: '15px', border: '4px solid #000', position: 'absolute', top: `${item.top}px`, left: `${item.left}px`, transition: `opacity ${0.2 + 0.15 * index}s` }}
                                        className={classNames({
                                            'hideAnimation': true,
                                            'hideGraphical': !buttonSelected[2],
                                            'show-off': item.content === null,
                                            'show-bad': item.content !== null,
                                            'show-normal': item.content === 3,
                                            'show-error': item.content === 5 || item.content === 7 || item.content === 8
                                        })}
                                    >
                                    </div>
                                )
                            })}
                            {rubberTankData.map((item: any, index: number) => {
                                return (
                                    <div key={index} style={{ width: '135px', height: '95px', position: 'absolute', top: `${item.top}px`, left: `${item.left}px`, backgroundColor: '#141414c4', transition: `opacity ${0.2 + 0.15 * index}s` }}
                                        className={classNames({
                                            'hideAnimation': true,
                                            'hideGraphical': !buttonSelected[1],
                                        })}
                                    >
                                        <BorderBox2>
                                            <div className='rubberTank'>
                                                <div className='illustration-title'>{handleArrayToObject(store.getState().glueBuckets, 'code')[item.code].name}</div>
                                                <div className='illustration-data'>液位: <span className='number'>{handleNull(item.content[0])}</span>mm</div>
                                                <div className='illustration-data'>压力: <span className='number'>{handleNull(item.content[1])}</span>MPa</div>
                                            </div>
                                        </BorderBox2>
                                    </div>
                                )
                            })}
                            {logistics[store.getState().projectInfo.shortCode].map((item: any, index: number) => {
                                return (
                                    !handleArrayToObject(store.getState().hasStopperWorkStations, 'code')[item.code] ?
                                        '' :
                                        <div key={index} style={{ width: '40px', height: '40px', border: `4px solid ${!!logisticsAnalysis[item.code] ? '#d72323' : '#000'}`, position: 'absolute', top: `${item.top}px`, left: `${item.left}px`, transition: `opacity ${0.2 + 0.15 * index}s` }}
                                            className={classNames({
                                                'hideAnimation': true,
                                                'logistics': true,
                                                'hideGraphical': !buttonSelected[3],
                                                'show-normal': item.isFull === false,
                                                'show-error': item.isFull === true,
                                            })}
                                        >
                                            {logisticsAnalysis[item.code] ? logisticsAnalysis[item.code].originalPosition + 1 : ''}
                                        </div>
                                )
                            })}
                            <div className={classNames({
                                'hideAnimation logisticsRanking': true,
                                'hideGraphical': !buttonSelected[3],
                            })}>
                                <div className='logisticsRanking-bg'></div>
                                <p>今日满料时长:</p>
                                {logisticsArr.map((item: any, index: number) => {
                                    return (
                                        <div className='logisticsRanking-list'>
                                            <div className='logisticsRanking-list-left'>No.{index + 1}</div>
                                            <div className='logisticsRanking-list-middle'>{item.workStationName}</div>
                                            <div className='logisticsRanking-error'>{formatHours(item.isFullHours)}</div>
                                        </div>
                                    )
                                })}
                            </div>

                            <div style={{ width: '300px', height: '195px', position: 'absolute', top: `315px`, left: `330px`, backgroundColor: '#141414c4', transition: `opacity 0.2s` }}
                                className={classNames({
                                    'hideAnimation': true,
                                    'hideGraphical': !buttonSelected[4],
                                })}
                            >
                                <BorderBox6 color={['#fff', '#fff']}>
                                    <div className='enery'>
                                        <div className='illustration-title'>能耗</div>
                                        <div className='enery-content'>
                                            <div className='enery-module'>
                                                {lineEnergyData.map((item: any, index: any) => {
                                                    return (
                                                        <div key={index} className='illustration-data'>{item.title}: <span className='number'>{item.content === null ? '--' : item.content}</span>{item.unit}</div>
                                                    )
                                                })}
                                            </div>
                                            <div className='enery-module'>
                                                {power.map((item: any, index: any) => {
                                                    return (
                                                        <div className='illustration-data'>{item.title}: <span className='number'>{item.content[0] === null ? '--' : item.content[0]}</span>V <span className='number'>{item.content[1] === null ? '--' : item.content[1]}</span>A</div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </BorderBox6>
                            </div>

                            <div className={classNames({
                                'hideAnimation environment': true,
                                'hideGraphical': !buttonSelected[5],
                            })}>
                                {/* <BorderBox6 color={['#fff', '#fff']}> */}
                                <div className='environment-bg'></div>
                                <div className='environment-content'>
                                    {environmentData.map((item: any, index: any) => {
                                        return (
                                            <div key={index} className='environment-module'>
                                                <div>{item.title}</div>
                                                <div className='illustration-data'><span className='number'>{item.content === null ? '--' : item.content}</span>{item.unit}</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {/* </BorderBox6> */}
                            </div>

                            <div style={{ width: '175px', height: '80px', position: 'absolute', top: `0px`, right: `17px`, backgroundColor: '#141414c4', transition: `opacity 0.2s` }}>
                                {/* <BorderBox6 color={['#fff', '#fff']}> */}
                                <div className='production'>
                                    <div className='production-content'>
                                        <div className='production-module'>
                                            {productionData.map((item: any, index: any) => {
                                                return (
                                                    <div key={index} className='production-data'>{item.title}: <span className='number'>{item.content === null ? '--' : parseFloat(item.content).toLocaleString()}</span>{item.unit}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {/* </BorderBox6> */}
                            </div>
                        </div>
                    </BorderBox9>
                </div>
                {/* 右侧信息 */}
            </div>
        </div >
    return content;
}

export { IOTD }