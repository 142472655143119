/*
 * @Description:质量缩略图
 * @Author: huixu
 */
import React from 'react'
import { LineLayout } from '@/layout'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { LineChart } from '@/components/chart/lineChart'
import * as API from '@/api/request'
import { useRequest } from 'ahooks'
import { searchParams } from '@/utils'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import moment from 'moment'
import _ from 'lodash'


const lineCode = searchParams().lineCode || null

let content = <Loading />
interface Props {
  data?: Object | Array<any>
}

/**
 * [
  {
    "defectId": 0,
    "defectCode": "string",
    "defectName": "string",
    "count": 0
  }
]
 */
const handleChartData = (data: Array<any>) => {
  let result = { xAxis: [], yAxis: [] }
  if (!Array.isArray(data)) return result
  if (data.length === 0) return result
  result.yAxis = data.map((item: any) => item.unSetOffSingleCount)
  result.xAxis = data.map((item: any) => moment(item.timeRange.beginTime).format('M/D'))
  return result
}
//测试数据
// const testData = [
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '涂胶断胶水',
//     count: 20,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '涂胶端胶',
//     count: 30,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '坏掉了',
//     count: 0,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '溢胶',
//     count: 34,
//   },
//   {
//     defectId: 0,
//     defectCode: 'string',
//     defectName: '涂处理',
//     count: 40,
//   },
// ]
const QualityNoVision = (props: Props) => {
  // 质量
  let { data, loading, error } = useRequest(
    () => {
      return Promise.all([
        API.Quality.getTodayFirstPassYield(lineCode),
        API.Quality.getTodayUnSetOnOffCount(lineCode),
        API.Quality.getUnSetOnOffCountByLast15Days(lineCode),
      ])
    },
    { pollingInterval: 3000 }
  )
  if (loading) return content
  if (error) {
    content = <Error />
    return content
  }
  content = <React.Fragment>
    <LineLayout justify="start" padding={[20, 20, 0, 30]}>
      <DigitalFlop count={_.floor(data[0] * 100, 1)} suffix="%" title="直通率" minWidth={180} type='count' />
      <DigitalFlop count={!!data[1][0].unSetOffSingleCount ? data[1][0].unSetOffSingleCount : 0} suffix="只" title="未下线" type='count' />
    </LineLayout>
    <LineChart margin="30px 0 0 0" id="qualityNoVision-thumbnail" height={215} title="最近15天未下线（只）" titleSize={18} data={handleChartData(data[2])} axisStyle={{ fontSize: 15 }} />
  </React.Fragment>
  return content
}
export { QualityNoVision }
