import { Call } from '@/api/request'
import { LineLayout } from '@/layout'
import { searchParams } from '@/utils'
import { useRequest } from 'ahooks'
import moment from 'moment'
import React from 'react'
import './index.scss'

const callTypesOrder = ['0', '1', '2', '3']
const handleCallingRecords = (data: Array<any>) => {
    if (!data || data.length === 0) return []
    //根据时间排序
    data.sort((a: any, b: any) => { return moment(a.beginTime).valueOf() - moment(b.beginTime).valueOf() })
    let result = []
    let resultObj = {}
    //提取时间最靠近的记录之一
    data.forEach((item: any) => {
        if (resultObj[item.callType] === undefined) {
            resultObj[item.callType] = item
        }
    });
    //根据自定义的callTypesOrder 进行排序
    callTypesOrder.forEach(item => {
        if (resultObj[item] !== undefined) {
            result.push(resultObj[item])
        } else {
            result.push(null)
        }
    })
    return result
}

const lineCode = searchParams().lineCode
let content = null
export const CallLight = () => {
    const { data, loading } = useRequest(() => Call.getCallingRecords(lineCode), { pollingInterval: 500 })
    if (loading) return content
    content = <div className='call-light'>
        <div className='call-light-content'>
            <LineLayout >
                {
                    handleCallingRecords(data as any).map((item: any, idx: number) => {
                        if (!!item) {
                            return <div className='call-light-item' key={idx}>
                                <div>{item.workStationName}</div>
                            </div>
                        }
                        return <div key={idx} />
                    })
                }
            </LineLayout>
        </div>
    </div>
    return content
}
