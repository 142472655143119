import React, { useEffect } from 'react';
import { store } from '@/store'
import { IOTD } from './iotD';
import { IOTY } from './iotY';

function IOTUpgradeSecond() {

    useEffect(() => {
        // console.log(store.getState().projectInfo.shortCode);
    }, [])

    return (
        <React.Fragment>
            {store.getState().projectInfo.shortCode.indexOf('Y') > -1 ? <IOTY /> : <IOTD />}
        </React.Fragment>
    );
}

export { IOTUpgradeSecond }