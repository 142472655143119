/*
 * @Author: hxhong
 * @Description: 
 */
import React, { useState } from 'react'
import { CenterLayout, Layout, LineLayout } from '@/layout'
import { LineChart } from '@/components/chart/lineChart'
import { BarChart } from '@/components/chart/barChart'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { Border1 } from '@/components/border'
import { Card } from '@/components/card'
import { useRequest } from 'ahooks'
import * as  API from '@/api/request'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import _ from 'lodash'
import { handleDataForLineChart, searchParams } from '@/utils'
import { getTimeRangeByUrl } from '@/utils/time'
import FormatterType from '@/components/chart/formatterType';
const lineCode = searchParams().lineCode || null
const toolbarIsVisiable = searchParams().toolbarIsVisiable
const pageName = (toolbarIsVisiable === 'false') ? '质量' : 'ZS-TEK智能化产线-质量'
const handleDataForBarChart = (data: Array<any>) => {
  return {
    xAxis: data.map(item => item.defectName),
    yAxis: data.map(item => item.count)
  }
}

const Quality = () => {
  const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())

  let hasVisionInspectionSystemRequest = useRequest(() => API.Quality.getHasVisionInspectionSystem(lineCode));
  let { data, loading, error } = useRequest(() => {
    return Promise.all([
      API.Quality.getStatisticValues(lineCode, timeRange),
      API.Quality.getFirstPassYieldByTimeRange(lineCode, timeRange),
      hasVisionInspectionSystemRequest.data ? API.Quality.getNotGoodCountByTimeRange(lineCode, timeRange) : API.Quality.getSetOnOffCountByTimeRange(lineCode, timeRange),
      API.Quality.getNotGoodCountByDefectTypes(lineCode, timeRange)
    ])
  }, { refreshDeps: [timeRange], ready: !hasVisionInspectionSystemRequest.loading })

  if (hasVisionInspectionSystemRequest.loading || loading) {
    return <Layout name={pageName}>
      <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
      <Loading />
    </Layout>
  }
  if (hasVisionInspectionSystemRequest.error || error) {
    return <Layout name={pageName}>
      <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
      <Error />
    </Layout>
  }
  const [staticValue, firstPassYield, notGoodCount, notGoodCountByDefectTypes]: Array<any> = data

  const handleNotGoodCountNoVision = (data: any) => {
    let arr = [];
    notGoodCount.forEach((item: any) => {
      arr.push({
        timeRange: item.timeRange,
        count: item[data]
      })
    });
    return arr;
  }

  let okRate = (okCount: number, notCount: number) => {
    if (okCount === 0) return 0
    return okCount / (okCount + notCount)
  }
  const numberCard = [
    { count: _.round(staticValue.firstPassYield * 100, 1), title: '直通率', suffix: '%' },
    {
      count: staticValue.okCount + staticValue.notGoodCount,
      title: '检测次数', suffix: '次'
    },
    { count: staticValue.notGoodCount, title: '缺陷次数', suffix: '次' },
  ]

  const numberNoVisionCard = [
    { count: _.round(staticValue.firstPassYield * 100, 1), title: '直通率', suffix: '%' },
    {
      count: staticValue.setOnSingleCount,
      title: '上线数量', suffix: '只'
    },
    { count: staticValue.unSetOffSingleCount, title: '未下线', suffix: '只' },
  ]
  return (
    <Layout name={pageName}>
      <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
      <LineLayout justify="start">
        {/* 左侧数据 */}
        <LineLayout direction="column" width={370} height={870} margin={[0, 30, 0, 30]} gap={50}>
          {
            hasVisionInspectionSystemRequest.data ?
              numberCard.map(item => {
                return <CenterLayout border={<Border1 />}>
                  <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.5} type='count' />
                </CenterLayout>
              }) :
              numberNoVisionCard.map(item => {
                return <CenterLayout border={<Border1 />}>
                  <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.5} type='count' />
                </CenterLayout>
              })
          }
        </LineLayout>
        {/* 右侧图表 */}
        <LineLayout width={1600} height={920} gap={30} direction='column' justify='start'>
          <Card width={1400} height={400} >
            <LineChart id="firsrYield" title="直通率(%)" height='93%' data={{
              xAxis: handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).xAxis,
              yAxis: handleDataForLineChart((timeRange as any).timeRangeType, firstPassYield).yAxis.map((item: any) => !item ? item : _.round(item * 100, 1)),
            }} titleSize={24}
              formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null}
            />
          </Card>
          {
            hasVisionInspectionSystemRequest.data ?
              <LineLayout height={450}>
                <LineChart id="notGood" title="缺陷次数(次)" height='93%' data={handleDataForLineChart((timeRange as any).timeRangeType, notGoodCount)} titleSize={24} formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} />
                <BarChart id="e2" title="缺陷类型(次)" height='93%' data={handleDataForBarChart(notGoodCountByDefectTypes)} titleSize={24} />
              </LineLayout> :
              <LineLayout height={450}>
                <LineChart id="onLine" title="上线数量(只)" height='93%' data={handleDataForLineChart((timeRange as any).timeRangeType, handleNotGoodCountNoVision('setOnSingleCount'))} titleSize={24} formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} />
                <LineChart id="offLine" title="未下线数量(只)" height='93%' data={handleDataForLineChart((timeRange as any).timeRangeType, handleNotGoodCountNoVision('unSetOffSingleCount'))} titleSize={24} formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} />
              </LineLayout>
          }
        </LineLayout>
      </LineLayout>
    </Layout>
  )
}
export { Quality }
