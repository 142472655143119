/*
 * @Author: hxhong
 * @Description: 
 */
import React from 'react'
import { Layout, LineLayout, EasyGridLayout, CenterLayout } from '@/layout'
import { Border1 } from '@/components/border'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { Card } from '@/components/card'
import { LineChart } from '@/components/chart/lineChart'
import { useRequest } from 'ahooks'
import { EnvironmentApi } from '@/api/request'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { searchParams } from '@/utils'
import { formatLineChartXAxis } from '@/utils/time'
import _ from 'lodash'
import FormatterType from '@/components/chart/formatterType';
const lineCode = searchParams().lineCode

const toolbarIsVisiable = searchParams().toolbarIsVisiable
const pageName = (toolbarIsVisiable === 'false') ? '环境' : 'ZS-TEK智能化产线-环境'
const getChartData = (environmentName: string, data: Array<any>) => {
  return {
    xAxis: formatLineChartXAxis(0, data),
    yAxis: data.map(item => {
      if (!!item.values) {
        //湿度特殊处理一下
        if (environmentName === 'humidness') {
          return _.round(item.values.humidness * 100)
        }
        return _.round(item.values[environmentName])
      }
      return null
    })
  }
}

const Environment = () => {
  const { data, loading, error } = useRequest(() => {
    return Promise.all([
      EnvironmentApi.getCurrentEnvironmentValues(lineCode),
      EnvironmentApi.getEnvironmentValuesByTimeRange(lineCode)
    ])
  })
  if (loading) return <Layout name={pageName}> <Loading /> </Layout>
  if (error) return <Layout name={pageName}> <Error /> </Layout>

  let [environments, environmentsByChart] = data
  // console.log(environmentsByChart)

  //左边数字
  let numberCards = [
    { title: '温度', count: _.round(environments.temperatureInC), type: 'count', suffix: '度' },
    { title: '湿度', count: _.round(environments.humidness * 100), type: 'count', suffix: '%' },
    { title: '噪音', count: _.round(environments.noiseIndB), type: 'count', suffix: 'db' },
    { title: 'PM25', count: _.round(environments.pM25), type: 'count', suffix: 'μg/m³' },
  ]

  //右侧图标
  let lineCharts = [
    { title: '温度(℃)', id: 'temperature', data: getChartData('temperatureInC', environmentsByChart) },
    { title: '湿度(%)', id: 'humidness', data: getChartData('humidness', environmentsByChart) },
    { title: '噪音(db)', id: 'noise', data: getChartData('noiseIndB', environmentsByChart) },
    { title: 'pm25(μg/m³)', id: 'pm25', data: getChartData('pM25', environmentsByChart) },
  ]


  return (
    <Layout name={pageName}>
      <LineLayout margin={[50, 30, 0, 30]} >
        <LineLayout>
          <LineLayout direction="column" width="250" height="900" gap={50}>
            {
              numberCards.map(item => {
                return <CenterLayout border={<Border1 />}>
                  <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.6} type='count' />
                </CenterLayout>
              })
            }
          </LineLayout>
          <EasyGridLayout rows={2} columns={2} width={1580} gap={50} margin={[0, 0, 0, 0]}>
            {
              lineCharts.map(item => {
                return <Card>
                  <LineChart id={item.id} title={item.title} data={item.data} height='395px' formatter={FormatterType.todayMoment} />
                </Card>
              })
            }
          </EasyGridLayout>
        </LineLayout>
      </LineLayout>
    </Layout>
  )
}
export { Environment }
