import React from 'react'
import { Layout, LineLayout } from '@/layout'
import { Card } from '@/components/card'
import * as Thumbnail from '@/components/thumbnail'
import { Border1 } from '@/components/border'
import { useRequest } from 'ahooks'
import { Call, Quality } from '@/api/request'
import { searchParams } from '@/utils'
import { Qrcode } from '@/components/qrCodes'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { CallLight } from '@/components/callLight'
import './index.scss'
import { store } from '@/store'
interface Props { }
/**当前的产线编号 */
const allLineCodes = ['1', '2', '3', '4', '5', '6']
/** 拼接参数 获取跳转的url */
const getJumpTo = (address: string): string => `${address}?lineCode=${searchParams().lineCode}`
const Home = () => {
  //获取是否该产线时候安装接警讯呼叫
  // let hasCallRequest = useRequest(() => Call.hasCallButtonSystem(searchParams().lineCode))

  const { loading, data, error } = useRequest(() => {
    return Promise.all([
      Call.hasCallButtonSystem(searchParams().lineCode),
      Quality.getHasVisionInspectionSystem(searchParams().lineCode),
    ])
  })
  if (!searchParams().lineCode || !allLineCodes.includes(searchParams().lineCode)) {
    return <Error value='错误的产线编号' />
  }
  if (loading) return <Loading />
  //获取是否该产线时候安装接警讯呼叫
  const hasCallButtonSystem = data[0]
  const ovenModuleWidth = hasCallButtonSystem ? 1355 : 1920
  let thumbnails: Array<any> = [
    { title: '生产', jumpTo: getJumpTo('/line/production'), children: <Thumbnail.Production />, border: <Border1 />, width: 420, icon: require('../../../assets/images/production.png') },
    { title: '质量', jumpTo: getJumpTo('/line/quality'), children: data[1] ? <Thumbnail.Quality /> : <Thumbnail.QualityNoVision />, border: <Border1 />, width: 420, icon: require('../../../assets/images/quality.png') },
    { title: '能源', jumpTo: getJumpTo('/line/equipment'), children: <Thumbnail.Equipment />, border: <Border1 />, width: 420, icon: require('../../../assets/images/equipment.png') },
    { title: '环境', jumpTo: getJumpTo('/line/environment'), children: <Thumbnail.Environment />, border: <Border1 />, width: 420, icon: require('../../../assets/images/th.png') },
  ]
  const pageName = store.getState().projectInfo.name
  return (
    <Layout name={pageName}>
      {
        hasCallButtonSystem ? <CallLight /> : null
      }
      <LineLayout margin={20} height={480}>
        {thumbnails
          .map((item) => (
            <Card title={item.title} jumpTo={item.jumpTo} showMoreBtn={true} border={item.border} width={item.width} children={item.children} icon={item.icon} />
          ))}
      </LineLayout>
      {/* 警讯、烘箱状态 */}
      <LineLayout height={440} justify='between' margin={[40, 20, 20, 20]}>
        {
          hasCallButtonSystem ?
            <Card title='呼叫'
              jumpTo={getJumpTo('/line/andon')}
              showMoreBtn={true} border={<Border1 />}
              width={420} children={<Thumbnail.Andon />}
              icon={require('../../../assets/images/call.png')} /> : null
        }
        <Card
          title='设备'
          jumpTo={getJumpTo('/line/oven')}
          showMoreBtn={true}
          border={<Border1 />}
          width={ovenModuleWidth}
          children={<Thumbnail.Oven />}
          icon={require('../../../assets/images/oven.png')} />

        <Qrcode />
      </LineLayout>
    </Layout>
  )
}
export { Home }
