import React, { useState } from 'react'
import { Layout, LineLayout, CenterLayout } from '@/layout'
import { TimePeriodSelector } from '@/components/timePeriodSelector/timePeriodSelector'
import { Border1 } from '@/components/border'
import { DigitalFlop } from '@/components/digitalFlop/digitalFlop'
import { Card } from '@/components/card'
import { LineChart } from '@/components/chart/lineChart'
import { useRequest } from 'ahooks'
import * as API from '@/api/request'
import Loading from '@/components/loading/loading'
import { Error } from '@/components/error'
import { handleBigCount, handleDataForLineChart, handleDataForTreemap, searchParams } from '@/utils'
import { getTimeRangeByUrl, timeRanges } from '@/utils/time'
import { round } from 'lodash'
import { Treemap } from '@/components/chart/treemapChart'
import { store } from '@/store'
import moment from 'moment'
import FormatterType from '@/components/chart/formatterType';
const Production = () => {
  const [timeRange, setTimeRange] = useState(getTimeRangeByUrl())

  const lineCode = useState(searchParams().lineCode)[0]

  const toolbarIsVisiable = useState(searchParams().toolbarIsVisiable)[0]
  const pageName = (toolbarIsVisiable === 'false') ? '生产' : `ZS-TEK_${store.getState().projectInfo.shortCode}智能化产线-生产`

  let { data, loading, error } = useRequest(() => {
    return Promise.all([
      API.Production.getStatisticValues(lineCode, timeRange),
      API.Production.getCapacityInPairPerHourByTimeRange(lineCode, timeRange),
      API.Production.getCountByTimeRange(lineCode, timeRange),
      API.Production.getCountByShoeType(lineCode, timeRange)
    ])
  }, { refreshDeps: [timeRange] })
  if (loading) {
    return <Layout name={pageName}>
      <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
      <Loading />
    </Layout>
  }
  if (error) {
    return (<Layout name={pageName}>
      <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
      <Error />
    </Layout>)
  }
  let [statisticValues, capacityc, count, shoeTypeCount]: Array<any> = data
  let numCardsLeft = [
    { count: statisticValues.count, suffix: '双', title: '产量', type: 'count' },
    { ...handleBigCount(statisticValues.capacity, statisticValues.capacityUnit), title: '产能', type: 'count' },
    { count: round(statisticValues.taktBySecondsPerPair), suffix: "秒/双", title: '节拍', type: 'count' },
    { count: statisticValues.shoeTypeCount, suffix: "款", title: '款式', type: 'count' }
  ]
  return (
    <Layout name={pageName}>
      <TimePeriodSelector change={(timeRange: any) => { setTimeRange(timeRange) }} />
      <LineLayout>
        <LineLayout justify="start">
          {/* 左边一些数据 */}
          <LineLayout direction="column" width="380" height="870" margin={[0, 30, 0, 30]} gap={30}>
            {
              numCardsLeft.map((item: any) => {
                return <CenterLayout border={<Border1 />}>
                  <DigitalFlop count={item.count} suffix={item.suffix} title={item.title} size={1.4} type={item.type} />
                </CenterLayout>
              })
            }
          </LineLayout>
        </LineLayout>
        {/* 中间图表 */}
        <LineLayout direction='column' width='1200' justify='between' gap={30}>
          <Card><LineChart id="production1" title="产量 (双)" height='93%' titleSize={24} data={handleDataForLineChart((timeRange as any).timeRangeType, count)} formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} /></Card>
          <Card><LineChart id="production2" title="产能 (双/小时)" height='93%' titleSize={24} data={handleDataForLineChart((timeRange as any).timeRangeType, capacityc)} formatter={timeRange.timeRangeType === 0 ? FormatterType.todayMoment : null} /></Card>
        </LineLayout>
        <Card title='款式' width={450}>
          {/* <SingleBarChart id='production3' data={shoeTypeCount} height={780} /> */}
          <Treemap id='shoetype' data={handleDataForTreemap(shoeTypeCount)} />
        </Card>
      </LineLayout>
    </Layout >
  )
}
export { Production }
